import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tabs"
export default class extends Controller {
  static classes = ["activeTab", "inactiveTab"];
  static targets = ["tab", "panel"];
  static values = {
    index: Number,
  };

  connect() {
    this.showTab();
  }

  change(event) {
    event.preventDefault(); // Prevent the default link behavior
    const id = event.currentTarget.dataset.id; // Get the id from the clicked link
    this.indexValue = this.tabTargets.findIndex((tab) => tab.dataset.id === id); // Find the index of the clicked tab
  }

  indexValueChanged() {
    this.showTab();
  }

  showTab() {
    this.panelTargets.forEach((panel, index) => {
      const tab = this.tabTargets[index];

      if (index === this.indexValue) {
        panel.classList.remove("hidden");
        tab.classList.add("text-white", "font-semibold", "bg-black/50");
      } else {
        panel.classList.add("hidden");
        tab.classList.remove("font-semibold", "bg-black/50");
      }
    });
  }
}
