import { Application } from "@hotwired/stimulus";
import Notification from "@stimulus-components/notification";
// import { Toggle } from "tailwindcss-stimulus-components";
import { Tabs } from "tailwindcss-stimulus-components";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

application.register("notification", Notification);
// application.register("toggle", Toggle);
application.register("tabs", Tabs);

export { application };
